import React, {PureComponent} from 'react'
import ReactDOM from 'react-dom'
import Loading, {Props as LoadingProps} from './index'
import c from './index.module.scss'
let $container: null | HTMLDivElement = null
let $ref:any = null
interface State {
  loading: boolean
}
class Wrap extends PureComponent<Partial<LoadingProps>, State> {
  public state = {
    loading: false
  }
  render () {
    const {cssTransitionConfig} = this.props
    const {loading} = this.state
    return loading && (
      <div className={c.staticFuntionWrap}>
        <Loading loading={loading} cssTransitionConfig={cssTransitionConfig}/>
      </div>
    )
  }
}
const getRef = (ref: any) => {
  $ref = ref
  $ref.setState({loading: true})
}
const onExited = () => {
  $container && document.body.removeChild($container)
  $container = null
}
const create = () => {
  $container = document.createElement('div')
  document.body.appendChild($container)
  ReactDOM.render(<Wrap ref={(ref) => getRef(ref)} cssTransitionConfig={{onExited: () => onExited()}}/>, $container)
}
const destroy = () => {
  $ref && $ref.setState({loading: false})
}
export const showLoading = (show: boolean) => {
  if (show) {
    create()
  } else {
    destroy()
  }
}
