const hostMapper = {
  client: {
    "m-teki.yfxn.lizhi.fm": '//clientoffice.qlizhi.com',
    // "mpre.qlizhi.com": '//clientpre.qlizhi.com',
    "mpre.qlizhi.com": '//client.qlizhi.com',  // 暂时没有预发环境
    "m.qlizhi.com": '//client.qlizhi.com',
    "wwwpre.qlizhi.com": '//client.qlizhi.com',  // 暂时没有预发环境
    "www.qlizhi.com": '//client.qlizhi.com',
    "qlizhi.com": '//client.qlizhi.com',
  }
}

export const getRequestHost = (key: keyof typeof hostMapper = 'client') => {
  const hostNameMap = hostMapper[key]
  return hostNameMap[location.hostname]
}
