import { createHttp } from '@/utils/http'
import { ResponseData } from "@/types/common";
import { getRequestHost } from "@/utils/getRequestHost";

const config = {
  baseURL: process.env.NODE_ENV === 'development' ?
  // "http://yapi.feoffice.lizhi.fm/mock/666"
  "http://clientoffice.qlizhi.com"
  :
  getRequestHost(),
}

const http = createHttp(config)

export const fetchDownloadUrl = (platform: number) =>
  http.get<ResponseData<{ url: string }>>(`/common/get_app`, { params: { platform } }).then(res => res.data)
