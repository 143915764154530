import React from 'react'
import BaseLoading, {LoadingProps} from '@/components/BaseLoading'
import {showLoading as showLoadingFun} from './staticFuntion'
import styles from './index.module.scss'
import InlineLoading, { Props as InlineLoadingProps} from './inlineLoading';
// type FilterPick<T, U> = Pick<T, Exclude<keyof T, U>>;
export interface Props extends LoadingProps {
  size?: 'default' | 'small'
}

type LoadingType = React.FunctionComponent<Props> & { Inline: React.FunctionComponent<InlineLoadingProps>}

const Loading: LoadingType = (props: Props) => {
  const {size, tip, border} = props
  const isDefault = size === 'default'
  return (
    <BaseLoading border={border} tip={tip} {...props} className={size && styles[size]} img={isDefault ? require('./img/loading-big.svg') : require('./img/loading-small.svg')}/>
  )
}
Loading.defaultProps ={
  size: 'default',
  border: true,
  tip: '努力加载中...'
}
Loading.Inline = InlineLoading

export const showLoading = showLoadingFun

export default Loading

