import React from "react";
import styles from "./index.module.scss";
import classnames from 'classnames'

interface CardProps {
  className?: string
  data: {
    img: string
    text: string
  }
}

export default React.memo<CardProps>(({ className, data }) => {

  const { text, img } = data

  return (
    <div className={ classnames(styles.cardContainer, className ? className : '') }>
      <div className={ styles.desc }>{ text }</div>
      <div className={ styles.imgWrapper }>
        <img src={ img } />
        <div className={ styles.playerDecorationWrapper }>
          <div className={ styles.point } />
          <div className={ styles.playerDecoration }>
            <div className={ styles.inner }></div>
            <div className={ styles.duration }>36″</div>
          </div>
        </div>
        <div className={ styles.textDecoration }>给TA听照片的故事</div>
        <div className={ styles.mountainDecoration }></div>
      </div>
    </div>
  )
})
