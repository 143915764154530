function getIosVersion(){
  const ua = decodeURI(navigator.userAgent.toLowerCase());
  let version = '';
  if (ua.indexOf("like mac os x") > 0) {
      const reg = /os [\d._]+/gi;
      const vInfo = ua.match(reg);
      version = (vInfo + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, "."); //得到版本号9.3.2或者9.0
      version = version.split('.')[0]; // 得到版本号第一位
  }

  return version;
}
export default getIosVersion
