import '@/shim'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import '@/index.scss'
import "./style.scss";
import Carousel from "./components/Carousel";
import { pageList } from "./config";
import { fetchDownloadUrl } from "./api";
import ua from "@/utils/ua";
import Dialog from "@/components/common/Dialog";
import sensorsInit from "@lizhife/sensors-sdk-teki";

const App = () => {
  const [ visible, setVisible ] = useState(false)
  const [ tipText, setTipText ] = useState('')
  const [ downloadUrl, setDownloadUrl ] = useState('')
  const sensors = useRef(null)

  useEffect(() => {
    const isProd = location.hostname === "www.qlizhi.com"
    sensorsInit(isProd, {}, { $title: "官网首页" }).then((res: any) => sensors.current = res)

    fetchDownloadUrl(ua.ios ? 2 : 1).then(res => {
      const { code, data } = res
      if (code === 0) {
        if (data.url) {
          setDownloadUrl(data.url)
        } else {
          if (ua.ios) {
            setTipText('iOS版本即将推出，敬请期待')
          } else {
            setTipText('安卓版本即将推出，敬请期待')
          }
        }
      } else {
        setTipText('服务异常')
      }
    })
  }, [])

  const handleDownload = useCallback((e:any) => {
    (sensors.current as any).quick('trackHeatMap', e.target, {
      $element_content: "官网立即下载",
      $title: "官网首页",
    })

    if (downloadUrl) {
      window.location.href = downloadUrl
    } else {
      setVisible(true)
    }
  }, [ downloadUrl ])

  return (
    <div className="homepage">
      <div className="logo" />
      <Carousel dataList={pageList} />
      <div className="download-btn" onClick={ handleDownload }></div>
      <div className="footer">
        <p>ICP备案号: <a href="http://beian.miit.gov.cn/" target="_blank" className="text">鄂ICP备17022944号-2</a></p>
        <p>公安备案号:
          <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42018502003392" target="_blank" className="text">
            鄂公网安备42018502003392号
          </a>
        </p>
      </div>

      <Dialog
        visible={ visible }
      >
        <div className="download-dialog">
          <div className="download-dialog-content">
            { tipText }
          </div>
          <div className="download-dialog-footer" onClick={() => setVisible(false)}>
            确定
          </div>
        </div>
      </Dialog>
    </div>
  )
}


ReactDOM.render(
  <App/>,
  document.getElementById('root')
)
