import first from "@/apps/Homepage/image/first.png";
import second from "@/apps/Homepage/image/second.png";
import third from "@/apps/Homepage/image/third.png";
import fourth from "@/apps/Homepage/image/fourth.png";

export const pageList = [
  {
    img: first,
    text: '一个有温度的生活打卡社区'
  },
  {
    img: fourth,
    text: '打卡你的生活方式'
  },
  {
    img: third,
    text: '用声音，给生活加点温度'
  },
  {
    img: second,
    text: '你的精神净土'
  },
]

