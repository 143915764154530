import React from 'react';
import RDialog from 'rmc-dialog'
import IDialogPropTypes from 'rmc-dialog/lib/IDialogPropTypes';
import prompt from './prompt'
import './styles/index.scss'
import classnames from 'classnames';
import getIosVersion from '@/utils/getIosVersion';
import repairIosBlankPage from "@/utils/repairIosBlankPage";
export interface DialogProps extends IDialogPropTypes {
  /** 宽度 */
  width?: number | string,
  /** 位置 默认中间 */
  position?: 'bottom' | 'center',
  /**
   * 19.7.11 为了解决https://github.com/wayou/wayou.github.io/issues/2
   * 只针对ios做修复，要注意，现在禁止touchmove，会导致内容区不可滚动
   */
  lockBody?: boolean,
  /** 只对position为bottom有效 */
  animationType?: 'slideUp'
}
interface State {
  isClosing: boolean
}
class Dialog extends React.PureComponent<DialogProps, State> {
  static alert = prompt('alert')
  static confirm = prompt('confirm')
  static defaultProps = {
    closable: false,
    position: 'center'
  }
  state = {
    isClosing: false
  }
  componentDidUpdate(prevProps: any) {
    if (prevProps.visible !== this.props.visible) {
      if (!this.props.visible) {
        this.handleLock(false)
      } else {
        this.handleLock(true)
        this.handleIosEventExcursion()
      }
    }
  }
  componentDidMount() {
    this.handleIosEventExcursion()
  }
  componentWillUnmount() {
    this.handleLock(false)
  }
  handleTouchmove = (e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }
  /**
   * 19.7.30 发现ios11出现弹窗里的点击事件触发位置偏移
   */
  handleIosEventExcursion = () => {
    const v = getIosVersion()
    if (v === '11') {
      repairIosBlankPage(false, 0)
    }
  }
  handleLock = (lock: boolean) => {
    if (this.props.lockBody) {
      if (lock) {
        document.body.scrollTop = 0
        document.addEventListener('touchmove', this.handleTouchmove, { passive: false });
      } else {
        document.removeEventListener('touchmove', this.handleTouchmove)
      }
    }
  }
  getAnimation = () => {
    const {position, animation, animationType} = this.props
    if (position === 'bottom' &&  animationType === 'slideUp') {
      return animationType
    }
    return animation || 'dialog'
  }
  public render() {
    const {
      children, onClose, width, position, wrapClassName,
      className,
      animation, maskAnimation, closable, ...other
    } = this.props

    const { isClosing } = this.state
    return (
      <RDialog
        prefixCls='lzDialog'
        className={classnames(className, { bottom: position === 'bottom' })}
        animation={this.getAnimation()}
        maskAnimation={maskAnimation || 'mask'}
        wrapClassName={classnames(wrapClassName, { center: position === 'center' })}
        closable={closable}
        onClose={(e) => {
          // 防止关闭动画未完成前多次触发
          if (isClosing) {
            return
          }
          this.setState({
            isClosing: true
          })
          onClose && onClose(e)
        }}
        afterClose={() => {
          this.setState({
            isClosing: false
          })
        }}
        {...other}>
        <div style={{ width }}>
          {
            children
          }
        </div>
      </RDialog>
    );
  }
}
export default Dialog
