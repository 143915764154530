/**
 * ios滚动超滚一屏，跳转后回退到该页面，会出现白屏
 * 见 https://github.com/vuejs/vue-router/issues/1990
 * https://github.com/vuejs/vue/issues/5533
 */
const repairIosBlankPage = (recover: boolean = true, offset: number = 1) => {
  const scrollTop = window.pageYOffset
  || document.documentElement.scrollTop
  || document.body.scrollTop
  || 0
  setTimeout(() => {
    window.scrollTo(0, offset === 0 ? 0 : scrollTop + offset)
    recover && window.scrollTo(0, scrollTop)
  })
}
export default repairIosBlankPage
