import { AxiosResponse } from "axios"

const responseInterceptor = () => {
  return [
    (response: AxiosResponse) => {
      // return response.data
      return response
    },
    (error: any) => {
      throw error
    }
  ]
}

const responseInterceptors = () => {
  return [
    responseInterceptor
  ]
}

export {
  responseInterceptors
}
