import React, { useState, useRef, useMemo } from "react";
import Scroll from "@/components/Scroll";
import styles from "./index.module.scss"
import PageCard from "../PageCard";
import classnames from 'classnames'


interface CarouselProps {
  onChange?: (current: number, prev: number) => void
  className?: string
  dataList: Array<any>
  qrCodeImage?: string
}

export default React.memo<CarouselProps>(({ dataList, onChange, qrCodeImage }) => {
  const carouselRef = useRef(null)
  const [ currentIndex, setCurrentIndex ] = useState(0)

  function handleChange(current: number, prev: number) {
    setCurrentIndex(current)
    onChange && onChange(current, prev)
  }

  const indicatorBarWidth = useMemo(() => {
    let l = dataList.length
    let w = 20 + (l - 1) * 12
    return { width: w + "px" }
  },[ dataList ])


  if (dataList.length > 0) {
    return (
      <div className={ styles.carouselContainer }>
        <Scroll
          onChange={ handleChange }
          initialSlide={ 0 }
          width={ 284 }
          dotBar={ false }
          slidesPerView="auto"
          ref={carouselRef}
          autoplay={ true }
          loop={ true }
        >
          <div className="swiper-wrapper">
            {
              dataList.length > 0 && dataList.map((item, i) => (
                <PageCard
                  key={`card_${i}`}
                  data={item}
                  className="swiper-slide"
                />
              ))
            }
          </div>
        </Scroll>
        <div className={ styles.indicatorWrapper } style={ indicatorBarWidth }>
          {
            dataList.length > 0 && dataList.map((t, i) => (
              <div
                key={`indicator_${i}`}
                className={ classnames(
                  styles.indicator,
                  currentIndex === i ? styles.active : ''
                ) }
              />
            ))
          }
        </div>
      </div>
    )
  } else {
    return (
      <div className={ styles.loading }></div>
    )
  }
})
