import React from 'react'
import styles from './index.module.scss'
import {CSSTransition} from 'react-transition-group'

export interface LoadingProps {
  loading: boolean,
  border?: boolean,
  img?: string,
  style?: React.CSSProperties,
  className?: string,
  tip?: boolean | string | React.ReactNode,
  cssTransitionConfig?: Partial<CSSTransition.CSSTransitionProps>
}

const LoadingContent: React.FunctionComponent<LoadingProps> = (props) => {
  const {loading, tip, cssTransitionConfig, img, className, style, border} = props
  return (
    <CSSTransition
      in={loading}
      timeout={300}
      classNames={`loading`}
      unmountOnExit={true}
      {...cssTransitionConfig}
    >
      <>
        {
          !tip && !border ? <img src={img} style={style} className={className} alt=""/> : (
            <span className={`${styles.tipWrap}`}>
              <span className={`${styles.tipContent} ${border ? styles.tipContentBorder : ''}`}>
                <img src={img} style={style} className={className} alt=""/>
                {tip && <span className={styles.tip}>{tip}</span>}
              </span>
            </span>
          )
        }
      </>
    </CSSTransition>
  )
}

export default class Loading extends React.PureComponent<LoadingProps, any> {
  public render() {
    const {children} = this.props
    return (
      <>
        {
          children ? (
            <div className={styles.wrap}>
              <div className={styles.inContainer}>
                <LoadingContent {...this.props}/>
              </div>
              {children}
            </div>
          ) : (
            <LoadingContent {...this.props}/>
          )
        }
      </>
    )
  }
}
