import React from 'react'
import ReactDOM from 'react-dom'
import './style.scss'
import Swiper, { SwiperOptions } from 'swiper'
import 'swiper/dist/css/swiper.min.css'

export interface ScrollProps extends SwiperOptions {
  // onChange 事件
  onChange?: (current: number, prev: number) => void
  // 内容
  children: React.ReactElement<
    React.ComponentProps<any> & { children: React.ReactNodeArray }
  >

  style?: React.CSSProperties
  className?: string,
  dotBar?: boolean,
  getScroller?: (scroller: Swiper) => void
}

interface State {
  activeIndex: number
}

class Scroll extends React.PureComponent<ScrollProps, State> {
  static defaultProps = {
    dotBar: true
  }
  constructor(props: ScrollProps) {
    super(props)
    this.state = {
      activeIndex: props.initialSlide || 0
    }
  }

  public scroller?: Swiper

  componentDidMount(): void {
    const { onChange, getScroller, ...swiperOption } = this.props
    const container = ReactDOM.findDOMNode(this) as HTMLLIElement
    const elem = container.querySelector('.cs-scroll-content') as HTMLLIElement
    this.scroller = new Swiper(elem, {
      ...swiperOption,
      on: {
        slideChangeTransitionEnd: () => {
          if (this.scroller) {
            const activeIndex = this.scroller.realIndex
            const prevActiveIndex = this.state.activeIndex
            this.setState({ activeIndex })
            onChange && onChange(activeIndex, prevActiveIndex)
          }
        },
        ...swiperOption.on
      }
    })
    getScroller && getScroller(this.scroller)
  }

  componentWillUnmount(): void {
    this.scroller && this.scroller.destroy(true, true)
  }

  public render() {
    const { activeIndex } = this.state
    const {dotBar} = this.props
    const itemLength = this.props.children.props.children.length
    const dotX = `${activeIndex * 100}%`
    const dotWidth = `${Math.ceil((1 / itemLength) * 100)}%`
    return (
      <div className="cs-scroll">
        <div className="cs-scroll-content swiper-container" style={{ height: this.props.height }}>
          {this.props.children}
        </div>
        {
          dotBar && (
            <div className="cs-scroll-dot-bar">
              <div className="cs-scroll-dot" style={{ transform: `translateX(${dotX})`, width: dotWidth }} />
            </div>
          )
        }
      </div>
    )
  }

  protected go = (index: number) => {
    this.scroller && this.scroller.slideTo(index)
  }
  protected next = () => {
    this.scroller && this.scroller.slideNext()
  }
  protected prev = () => {
    this.scroller && this.scroller.slidePrev()
  }
}

export default Scroll
