import React from 'react'

export type Props = {
  visible: boolean,
  text?: string,
}

const loadingStyle: any = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50px',
  fontSize: '13px',
  color: 'rgba(0, 0, 0, 0.3)',
}

export default function InlineLoading(props: Props) {
  const {visible, text} = props

  if (!visible) return null

  return (
    <div style={{...loadingStyle}}>
      {
        text ? text : <img src={require('./img/loading-small.svg')} alt="" />
      }
    </div>
  )
}