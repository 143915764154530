import React, { PureComponent, ReactNode } from 'react'
import ReactDOM from 'react-dom';
import Dialog from './index'
import styles from './styles/prompt.module.scss'
import Loading from '../Loading';
import classnames from 'classnames';
import history from '@/utils/history'
type Handle = () => Promise<any> | void
export interface PromptProps {
  type: 'alert' | 'confirm'
  title?: string | ReactNode,
  content?: string | ReactNode,
  onOk?: Handle,
  onOkBtnStyle?: React.CSSProperties
  onCancel?: Handle,
  onCancelStyle?: React.CSSProperties
  onOkText?: ReactNode,
  onCancelText?: ReactNode,
  lockBody?: boolean,
  footer?: boolean
}
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>
interface State {
  visible: boolean,
  loading: boolean,
  cancelLoading: boolean
}
class DialogWrap extends PureComponent<PromptProps, State> {
  static defaultProps = {
    title: '提示',
    footer: true
  }
  dialogRef: any = null
  state = {
    visible: true,
    loading: false,
    cancelLoading: false
  }
  isClosing = false
  componentDidMount = () => {
    // 解决路由跳转了弹窗却还没销毁
    history.listen((location, action) => {
      this.state.visible && this.close()
    })
  }
  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading
    })
  }
  toggleCancelLoading = () => {
    this.setState({
      cancelLoading: !this.state.cancelLoading
    })
  }
  close = () => {
    this.setState({ visible: false })
  }
  handleClick = async (handle?: Handle, isCancle?: boolean) => {
    const handleLoading = isCancle ? this.toggleCancelLoading : this.toggleLoading
    if (this.isClosing) {
      return
    }
    this.isClosing = true
    if (!handle) {
      this.close()
      return
    }
    const result = handle()
    if (result && result instanceof Promise) {
      handleLoading()
      try {
        await result.finally(() => {
          this.isClosing = false
          handleLoading()
        })
        this.close()
      } catch (error) {
        console.log(error)
      }
    } else {
      this.close()
    }
  }

  render() {
    const { visible, loading, cancelLoading } = this.state
    const { title, content, footer, onCancelText, onOkText, type, onCancel, onOk, lockBody, onOkBtnStyle, onCancelStyle } = this.props
    return (
      <Dialog ref={ref => this.dialogRef = ref} lockBody={lockBody} visible={visible} afterClose={() => {
        this.isClosing = false
      }}>
        <div className={styles.wrap}>
          {
            typeof title === 'string' ? (
              <div className={styles.title}>{title}</div>
            ) : title || null
          }
          {
            typeof content === 'string' ? (
              <div className={styles.content}>{content}</div>
            ) : content || null
          }
          {footer && (
            <div className={styles.footerWrap}>
              {type === 'confirm' && <div style={onCancelStyle} className={classnames(styles.cancalBtn, { [styles.loading]: cancelLoading })} onClick={() => this.handleClick(onCancel, true)}>
                {onCancelText || '取消'}{cancelLoading && <Loading style={{ marginLeft: '8px' }} size='small' border={false} tip={false} loading={true} />}
              </div>}
              <div style={onOkBtnStyle} className={classnames(styles.okBtn, { [styles.loading]: loading, [styles.alert]: type === 'alert' })} onClick={() => this.handleClick(onOk)}>
                {onOkText || '确定'}{loading && <Loading style={{ marginLeft: '8px' }} size='small' border={false} tip={false} loading={true} />}
              </div>
            </div>
          )}
        </div>
      </Dialog>
    )
  }
}
export default function prompt(type: PromptProps['type']) {
  return (config: Omit<PromptProps, 'type'> = {}) => {
    let ref: any = null
    const div = document.createElement('div');
    document.body.appendChild(div);
    const close = () => {
      if (ref) {
        ref.setState({
          visible: false
        })
      }
    }
    ReactDOM.render((
      <DialogWrap ref={r => ref = r} type={type} {...config}></DialogWrap>
    ),
      div,
    );
    return close
  }
}
