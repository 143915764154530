const ua = navigator.userAgent

export default {
  iphone: !!ua.toLowerCase().match('iphone'),
  ipod: !!ua.toLowerCase().match('ipod'),
  ipad: !!ua.toLowerCase().match('ipad'),
  android: ua.toLowerCase().indexOf('android') > -1,
  qqBrowser: ua.indexOf('MQQBrowser') > -1,
  quarkBrowser: ua.indexOf('Quark') > -1,
  wx: /MicroMessenger/i.test(ua),
  qq: /QQ\//i.test(ua),
  lz: /lizhifm/i.test(ua),
  weibo: ua.toLowerCase().indexOf('weibo') > -1,
  iphoneX: /iphone/gi.test(ua) && window.screen.height >= 812,
  iphoneXR: /iphone/gi.test(ua) && window.screen.height === 896,
  ios: !!(ua.toLowerCase().match('iphone') || ua.toLowerCase().match('ipod') || ua.toLowerCase().match('ipad')),
}
