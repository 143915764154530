

import axios, { AxiosInstance, AxiosPromise } from "axios";
import * as querystring from 'querystring'
import { responseInterceptors } from './interceptor'
import AxiosRequestConfig from './AxiosRequestConfig'
import {Omit} from '@/types/common'
import precache from '@lizhife/api-prefetch/dist/esm/axios/precache'

interface _AxiosInstance extends Omit<AxiosInstance, 'get'> {
  get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T>;
}

// axios 默认配置
const defaultConfig: AxiosRequestConfig = {
  // baseURL: '/vodapi',
  timeout: 1000 * 15,
  // withCredentials: false, // 跨域 * 不允许带 cookie，需要后端明确指定域名
  // validateStatus: (status) => !(status >= 400 && status < 600),
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  },
  paramsSerializer: (params) => querystring.stringify(params),
  _withToken: false, // 站内注入 app token 到 header，站外注入 web token 到 header
  _customCallbackForResponseError: undefined, // res code 401 的回调处理
  _customCallbackForServerError: undefined, // res code 500+ 网络问题等 的回调处理
  _customCallbackForNetworkError: undefined,
  _noCatch: false,
  // _login: {
  //   type: 7,
  //   url: window.location.href,
  //   urlShareable: false,
  //   isFull: true,
  //   isLight: false,
  //   wk: true
  // }
}


const createHttp = (config?: AxiosRequestConfig): _AxiosInstance => {
  config = {
    ...defaultConfig,
    ...config,
    headers: {
      ...defaultConfig.headers,
      ...(config && config.headers),
    }
  }

  const http = axios.create(config)

  http.interceptors.request.use(precache as any)

  responseInterceptors().forEach(interceptor => {
    http.interceptors.response.use(...interceptor())
  })

  return http
}

export {
  createHttp
}
